<template>
  <Top />
  <div class="main">
    <div class="container">
      <span class="title">{{ lang.v.ExchangeStarCard }}</span>
      <span class="tips">{{ lang.v.EnterMembershipTips }}</span>
      <input
        @input="onInput"
        type="text"
        class="code-input"
        placeholder="Please enter the redemption code"
        v-model="codeInput"
      />
      <span class="tips-input" v-show="tips.tipsVisible && !tips.tipsAmouts">{{
        tips.tipsLanguage == 0 ? lang.v.ExchangeNotUsed : lang.v.ExchangeUsed
      }}</span>
      <span class="tips-input" v-show="tips.tipsAmouts">{{
        CardType + ':' + tips.tipsAmouts
      }}</span>
      <span class="tips-input" v-show="noCode">{{ lang.v.noExchange }}</span>
    </div>
    <button class="code-button" @click="onSubmitCode">
      {{ lang.v.submitItem }}
    </button>
    <van-overlay :show="dialogVisible">
      <div class="wrapper" @click.stop>
        <div class="dialog">
          <img
            class="dialog-image"
            src="~@/assets/img/redemption_dialog_image.png"
            alt=""
          />
          <span class="dialog-text">{{ enterMembership }}</span>
          <button class="dialog-btn" @click="dialogVisible = false">
            {{ lang.v.confirm }}
          </button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script setup>
import { convert_use, getByCode } from '@/api/fetcher';
import message from '@/utils/message';
import { ref, reactive } from 'vue';
import { lang } from '@/lang';
import Top from '@/components/Top/top.vue';
const codeInput = ref('');
const dialogVisible = ref(false);
const days = ref(0);
const CardType = ref('M-Card');
const enterMembership = ref('');
let timer = null;
const tips = reactive({ tipsVisible: false, tipsLanguage: 0, tipsAmouts: 0 });
const noCode = ref(false);
const onSubmitCode = async () => {
  if (codeInput.value === '') return message.error(lang.v.noRedemptionTips);
  await convert_use({ code: codeInput.value }).then(res => {
    message.success(lang.v.success5);
    dialogVisible.value = true;
    days.value = res;
    enterMembership.value = lang.v.EnterMembership.replace(
      '$y',
      days.value
    ).replace('$x', CardType.value);
  });
};
const onInput = () => {
  if (timer !== null) {
    clearTimeout(timer);
  }
  if (codeInput.value === '') {
    tips.tipsVisible = false;
    noCode.value = false;
    return;
  }
  timer = setTimeout(async () => {
    const res = await getByCode({ code: codeInput.value });
    if (res) {
      tips.tipsVisible = true;
      noCode.value = false;
      tips.tipsLanguage = res.status;
      tips.tipsAmouts = res.amount;
      if (res.talentCardType == 1) {
        CardType.value = 'M-Card';
      } else {
        CardType.value = 'M-Card Pro';
      }
    } else {
      noCode.value = true;
      tips.tipsVisible = false;
      tips.tipsAmouts = 0;
    }
  }, 500);
};
</script>
<style scoped lang="less">
.main {
  display: flex;
  flex-direction: column;
  margin: 0 0.14rem;

  .container {
    margin-top: 0.2rem;
    border-radius: 12px;
    padding: 0.14rem;
    // height: 1.24rem;
    margin-left: 0.12rem;
    margin-right: 0.16rem;
    background-color: #fff;
    font-size: 0.14rem;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 0.14rem;
      color: #222222;
    }
    .tips {
      margin-top: 0.06rem;
      margin-bottom: 0.1rem;
      color: #888888;
      font-size: 0.12rem;
    }
    .tips-input {
      margin-top: 0.08rem;
    }
    .code-input {
      height: 0.42rem;
      background-color: #f6f7f8;
      border-radius: 0.1rem;
      border: #ffffff;
      padding: 0 0.08rem;
    }
  }

  .code-button {
    background: linear-gradient(90deg, #f46b45 0%, #eea849 100%);
    border-radius: 10px 10px 10px 10px;
    color: #ffffff;
    outline: none;
    padding: 0.1rem 0;
    margin: 0.2rem 0.14rem 0 0.14rem;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .dialog {
      width: 3.35rem;
      height: 3rem;
      background: #ffffff;
      border-radius: 0.12rem;
      display: flex;
      flex-direction: column;
      padding: 0 0.14rem;
      align-items: center;
      justify-content: center;
      .dialog-image {
        width: 1.24rem;
        height: 1.24rem;
      }
      .dialog-text {
        width: 1.5rem;
        font-family: PingFang SC, PingFang SC;
        margin-top: 0.1rem;
        margin-bottom: 0.24rem;
        font-weight: 400;
        font-size: 0.12rem;
        color: #222222;
        line-height: 0.18rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
      .dialog-btn {
        width: 3.04rem;
        height: 0.42rem;
        background: #ed931e;
        border-radius: 0.24rem;
      }
    }
  }
}
</style>
