export function clearLocalUser() {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('refresh_Token');
  localStorage.removeItem('access_token');
}

export function hasLoginCache() {
  return (
    localStorage.getItem('refresh_Token') ||
    localStorage.getItem('access_token')
  );
}
